<template>
  <div class="container px-0 h-100">
    <div class="wide-box-full">
      <div class="card border-radius-1 mb-4 pb-4">
        <div
          class="card-header-profile border-bottom d-flex align-items-end pb-4"
        >
          <div class="card-title-profile flex-grow-1">
            <div class="d-flex flex-row">
              <div class="px-2">
                <img src="/media/brand/profile-avatar.png" alt="" />
              </div>
              <div class="p-4 profile-fullname align-middle">
                Izabela<br />
                Słowikowska
              </div>
              <div class="px-4 ">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">113 zł</h3>
                  <span class="sub-title p-1">Prowizja 09/21</span>
                </div>
              </div>
              <div class="px-4">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">10 polis</h3>
                  <span class="sub-title p-1">Wznowienia 09/21</span>
                </div>
              </div>
              <div class="px-4 ">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">30 polis</h3>
                  <span class="sub-title p-1">Sprzedaż 08/21</span>
                </div>
              </div>
              <div class="px-4 ">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">525 zł</h3>
                  <span class="sub-title p-1">Prowizja 08/21</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div
            class="mb-3 d-flex justify-content-center flex-row nav-tabs profile-nav-tabs"
          >
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-account' }">
                Konto
              </router-link>
            </div>
            <div class="profile-nav-link nav-link active">
              <router-link :to="{ name: 'profile-policies' }">
                Polisy
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-renewals' }">
                Wznowienia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-issues' }">
                Sprawy
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-leads' }">
                Leady
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-settlements' }">
                Rozliczenia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-calendar' }">
                Kalendarz
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-trainings' }">
                Szkolenia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-permissions' }">
                Uprawnienia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-rodoidd' }">
                RODO/IDD
              </router-link>
            </div>
          </div>

          <b-table
            :items="policiesList.items"
            :fields="policiesList.fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            thead-class="profile-tblheader"
            responsive="sm"
            show-empty
            class="pt-4"
          >
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfilePoliciesPageView",
  data() {
    return {
      selected: [],
      sortBy: "data",
      sortDesc: false,
      policiesList: {
        fields: [
          {
            key: "polisa",
            label: "Polisa/Aneks",
            sortable: false
          },
          {
            key: "data",
            label: "Data sp.",
            sortable: true
          },
          {
            key: "tu",
            label: "TU",
            sortable: true
          },
          {
            key: "produkt",
            label: "Produkt",
            sortable: true
          },
          {
            key: "koniec",
            label: "Koniec",
            sortable: true
          },
          {
            key: "klient",
            label: "Klient",
            sortable: true
          },
          {
            key: "inkaso",
            label: "Inkaso",
            sortable: true
          },
          {
            key: "przypis",
            label: "Przypis",
            sortable: true
          },
          {
            key: "rodo",
            label: "RODO",
            sortable: false
          },
          {
            key: "idd",
            label: "IDD",
            sortable: false
          },
          {
            key: "s",
            label: "S",
            sortable: false
          }
        ],
        items: [
          {
            polisa: "0987584930",
            data: "04-08-2021",
            tu: "Ergo Hestia",
            produkt: "Travel",
            koniec: "03-08-2022",
            klient: "Kazimierz Juszczykiew",
            inkaso: "10.567 zł",
            przypis: "10.567 zł",
            rodo: "Brak",
            idd: "O-OK",
            s: "A"
          },
          {
            polisa: "M29865980600",
            data: "11-08-2021",
            tu: "PZU",
            produkt: "Majątek Jupitor",
            koniec: "10-08-2022",
            klient: "Anna Janczar",
            inkaso: "1.465 zł",
            przypis: "1.465 zł",
            rodo: "OK",
            idd: "D-Brak",
            s: "P"
          },
          {
            polisa: "679r03",
            data: "20-08-2021",
            tu: "InterRisk",
            produkt: "NNW - indyw.",
            koniec: "19-08-2022",
            klient: "PHU-EXBUD",
            inkaso: "345 zł",
            przypis: "345 zł",
            rodo: "Brak",
            idd: "O-OK",
            s: "P"
          },
          {
            polisa: "0987584930",
            data: "04-08-2021",
            tu: "Ergo Hestia",
            produkt: "Travel",
            koniec: "03-08-2022",
            klient: "Kazimierz Juszczykiew...",
            inkaso: "10.567 zł",
            przypis: "10.567 zł",
            rodo: "Brak",
            idd: "O-OK",
            s: "A"
          },
          {
            polisa: "M29865980600",
            data: "11-08-2021",
            tu: "PZU",
            produkt: "Majątek Jupiter",
            koniec: "10-08-2022",
            klient: "Anna Janczar",
            inkaso: "1.465 zł",
            przypis: "1.465 zł",
            rodo: "Brak",
            idd: "O-OK",
            s: "P"
          },
          {
            polisa: "6479r03",
            data: "20-08-2021",
            tu: "InterRisk",
            produkt: "NNW - indyw.",
            koniec: "19-08-2022",
            klient: "PHU EXBUD",
            inkaso: "345 zł",
            przypis: "345 zł",
            rodo: "1,2",
            idd: "D-OK",
            s: "P"
          },
          {
            polisa: "6479r03",
            data: "20-08-2021",
            tu: "InterRisk",
            produkt: "UNŻ",
            koniec: "19-08-2022",
            klient: "PHU EXBUD",
            inkaso: "345 zł",
            przypis: "345 zł",
            rodo: "1,2",
            idd: "D-OK",
            s: "P"
          },
          {
            polisa: "0987584930",
            data: "04-08-2021",
            tu: "Ergo Hestia",
            produkt: "Travel",
            koniec: "03-08-2022",
            klient: "Kazimierz Juszczykiew...",
            inkaso: "10.567 zł",
            przypis: "10.567 zł",
            rodo: "Brak",
            idd: "O-OK",
            s: "A"
          },
          {
            polisa: "m29865980600",
            data: "11-08-2021",
            tu: "PZU",
            produkt: "Mieszkanie",
            koniec: "10-08-2022",
            klient: "Anna Janczar",
            inkaso: "1.465 zł",
            przypis: "1.465 zł",
            rodo: "OK",
            idd: "D-brak",
            s: "P"
          },
          {
            polisa: "6479r03",
            data: "20-08-2021",
            tu: "InterRisk",
            produkt: "Dom - H7",
            koniec: "19-08-2022",
            klient: "PHU EXBUD",
            inkaso: "345 zł",
            przypis: "345 zł",
            rodo: "1,2",
            idd: "D-OK",
            s: "P"
          },
          {
            polisa: "M29865980600",
            data: "11-08-2021",
            tu: "PZU",
            produkt: "Mieszkanie",
            koniec: "10-08-2022",
            klient: "Anna Janczar",
            inkaso: "1.465 zł",
            przypis: "1.465 zł",
            rodo: "OK",
            idd: "D-Brak",
            s: "P"
          },
          {
            polisa: "6479r03",
            data: "20-08-2021",
            tu: "InterRisk",
            produkt: "Dom - H7",
            koniec: "19-08-2022",
            klient: "PHU EXBUD",
            inkaso: "345 zł",
            przypis: "345 zł",
            rodo: "1,2",
            idd: "D-OK",
            s: "P"
          },

        ]
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
